.MuiPaper-root .icon-status {
    font-size: 1em;
    margin-right: 1em;
}

.MuiPaper-root .icon-status>svg {
    font-size: 1em;
}

.MuiPaper-root .icon-status>svg.success {
    color: green;
}

.MuiPaper-root .icon-status>svg.error {
    color: red;
}

.MuiPaper-root .icon-status>svg.pending {
    color: rgb(188, 185, 185);
}