.dettagliOfferta {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
}

.dettagliOfferta .title {
  font-size: 85% !important;
  color: var(--bs-secondary);
}


/* STATO LAVORO */
.dettagliOfferta .statoLavoro {
  background: var(--bs-secondary);
  padding: 1em;
}

.dettagliOfferta .statoLavoro>desc {
  color: var(--bs-tertiary);
  display: block;
  text-transform: uppercase;
  font-size: 110%;
  font-weight: bold;
}

.dettagliOfferta .statoLavoro>span {
  color: white;
  display: block;
  font-size: 90%;
}

.dettagliOfferta .statoLavoro>.progress {
  height: 2em;
}

/* PROJECT NAME */
.dettagliOfferta .project-name {
  background: var(--bs-primary);
  padding: 1em 0;
}

.dettagliOfferta .project-name>div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.dettagliOfferta .project-name .budget, .template .budget {
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  width: 95%;
  font-size: 80%;
  line-height: 1.3em;
  margin: auto;
  text-align: end;
}

.dettagliOfferta .project-name .budget>*:nth-child(1), .template .budget>*:nth-child(1) {
  color: var(--bs-secondary);
  font-weight: normal;
}

.dettagliOfferta .project-name .budget>*:nth-child(2), .template .budget>*:nth-child(2) {
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 110%;
}

.dettagliOfferta .project-name h5 {
  font-weight: bold;
  color: white;
  font-size: 150%;
}

/* COMPETENZE RICHIESTE */
.dettagliOfferta .list-skills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
}

.dettagliOfferta .list-skills>span {
  display: block;
  width: 100%;
  color: #013057;
}

.dettagliOfferta .list-skills .skill {
  background: #e9f6f9;
  color: #0565a2;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-align: center;
  text-transform: capitalize;
  font-size: 90%;
  font-weight: bold;
  padding: 0.3em 0.5em;
}

/* PIANO SELEZIONATO */
.dettagliOfferta .selected-plan {
  padding: 1rem 2rem;
  text-align: left;
}

.dettagliOfferta .selected-plan>span:nth-child(1) {
  text-transform: capitalize;
  display: block;
}

.dettagliOfferta .selected-plan>span:nth-child(2) {
  display: block;
  font-weight: bold;
  font-size: 120%;
  color: var(--bs-primary);
}

/* DESCRIZIONE PROGETTO */
.dettagliOfferta .description {
  padding: 1rem;
  margin-top: 1rem;
  color: var(--bs-secondary);
}

.dettagliOfferta .description>span:nth-child(1) {
  display: block;
  font-size: 100%;
}

.dettagliOfferta .description>span:nth-child(2) {
  display: block;
  font-size: 80%;
  line-height: 22px;
}

/* TEMPLATE BOX */
.dettagliOfferta .template {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.dettagliOfferta .template .info {
  width: 100%;
  font-size: 90%;
  color: var(--bs-secondary);
  margin-bottom: 0.8rem;
}

.dettagliOfferta .template .info span {
  color: var(--bs-primary);
}

/* OTHER LINK - DOC */
.dettagliOfferta .links {
  transform: scale(0.9);
}


.dettagliOfferta .info-link,
.dettagliOfferta .info-doc,
.dettagliOfferta .location {
  padding: 1rem;
}

.dettagliOfferta .info-link {
  color: gray;
  font-size: 90%;
}

.dettagliOfferta .info-link span {
  display: block;
  color: var(--bs-primary);
  font-weight: bold;
  font-size: 110%;
}

.dettagliOfferta .info-doc>* {
  color: var(--bs-secondary);
  display: block;
  font-size: 90%;
}

.dettagliOfferta .info-doc>li {
  color: var(--bs-primary);
  padding: 0.3rem 0;
  font-size: 80%;
  cursor: pointer;
}

.dettagliOfferta .info-doc .description {
  padding: 0;
  color: gray;
  font-size: 90%;
}

.passwd-staging {
  background: rgba(128, 128, 128, 0.205);
  border-radius: 10px;
  padding: 0 10px;
  border: 1px solid lightgray;
}


/* AVATAR CLIENT */
.avatar-client {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 80%;
  font-weight: bold;
  gap: 10px;
}

.avatar-client>img {
  border-radius: 50%;
  border: 2px solid var(--bs-primary);
  width: 50px;
  height: 50px;
}

#budget-offer {
  padding: 0.1em 0.1em 0.1em 2em;
  font-size: 150%;
  color: var(--bs-secondary);
}

label[data-default='€'] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

label[data-default='€']:after {
  margin: none;
  position: absolute;
  content: attr(data-default);
  font-size: 150%;
  padding-left: 1em;
  font-weight: bold;
  color: var(--bs-secondary);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.smu-add-file {
  margin-top: .5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid rgba(128, 128, 128, 0.715);
  background: white;
  transition: 0.5s;
  width: 35px;
  height: 35px;
}

.smu-add-file>* {
  color: gray;
}

.smu-add-file:hover {
  border: 2px solid var(--bs-primary);
}

.add-products .prod {
  padding: 1em;
}

.add-products .prod>* {
  border-radius: 15px;
  border: 2px solid var(--bs-success);
  color: var(--bs-success);
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0.8em;
}

.add-products .prod desc:first-child {
  color: GREEN;
  font-size: 90%;
}

.offertaFooter {
  background: var(--bs-tertiary);
  padding: .5em;
}

.offertaFooter button {
  margin: 0;
}

.dettagliOfferta .icon-action>div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5em;
  gap: 8px;
  font-size: 90%;
  font-weight: bold;
  cursor: pointer;
  transition: all .5s;
}

.dettagliOfferta .icon-action>div:nth-child(1):hover {
  background: rgb(197, 241, 197);
}

.dettagliOfferta .icon-action>div:nth-child(2):hover {
  background: rgb(209, 250, 247)
}


.dettagliOfferta .icon-action .css-eimhud-MuiSvgIcon-root {
  font-size: 1.5em !important;
}

.dettagliOfferta .icon-action>div:nth-child(1) .css-eimhud-MuiSvgIcon-root {
  color: rgb(12, 179, 12);
}

.dettagliOfferta .icon-action>div:nth-child(2) .css-eimhud-MuiSvgIcon-root {
  color: rgb(111, 192, 230)
}






.dettagliOfferta .image-project {
  display: none;
}
.dettagliOfferta .budget > desc > span:first-child  {
  display: none;
}
.dettagliOfferta .freelance-review {
  padding: 1em;
  gap:20px;
  font-size: 90%;
  display: none;
}
.dettagliOfferta .freelance-review > *:first-child {
  display: flex;
  align-items: center;
}
.dettagliOfferta .freelance-review > * > b  {
  color: var(--bs-primary);
  text-transform: capitalize;
}

/* MOSTRA OFFERTA - 2° type */
.dettagliOfferta.show-offer .description,
.dettagliOfferta.show-offer .client-panel,
.dettagliOfferta.show-offer .info-doc:nth-child(3),
.dettagliOfferta.show-offer .add-products {
  display: none;
}

.dettagliOfferta.show-offer .userDetailsList {
  padding-top: .5em;
}

.dettagliOfferta.show-offer .image-project {
  padding: .5em;
  display: block;
}
.dettagliOfferta.show-offer .budget > desc > span:nth-child(1)  {
  display: inline;
}
.dettagliOfferta.show-offer .budget > desc > span:nth-child(2)  {
  display: none;
}
.dettagliOfferta.show-offer .freelance-review {
  display:block;
}