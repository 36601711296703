.storico-progetti .title {
    color: var(--bs-primary);
    padding-left: 1em;
    font-size: 80%;
    font-weight: 600;
    text-transform: uppercase;
}

.offerte-client-profilo {
    overflow-x: auto;
}