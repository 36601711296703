.makeOfferProject {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
}

.makeOfferProject .title {
  font-size: 85% !important;
  color: var(--bs-secondary);
}

.makeOfferProject .project-name {
  background: var(--bs-secondary);
}

.background-grey { background:#EDF1F4; }

/* BUDGET + NOME PROGETTO */
.makeOfferProject .project-name .budget, .template .budget {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  width: 95%;
  max-width: 200px;
  padding-top: 1.5em;
  font-size: 70%;
  margin: auto;
  font-weight: lighter;
  box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.1);
  transform: scale(0.7);
}

.makeOfferProject .project-name .budget > span:nth-child(1), .template .budget>*:nth-child(1) {
  color: #0565a2;
  font-weight: bold;
  font-size: 190%;
  padding-bottom: 0.5em;
  margin-top: -0.4em;
}

.makeOfferProject .project-name .budget > span:nth-child(2), .template .budget>*:nth-child(2) {
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 120%;
  text-align: center;
  background: #0565a2;
  padding: 0.4em 0;
}

.makeOfferProject .project-name h1 {
  font-weight: bold;
  color: white;
  font-size: 120%;
  text-transform: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

/* COMPETENZE RICHIESTE */
.makeOfferProject .list-skills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
}

.makeOfferProject .list-skills > span {
  display: block;
  width: 100%;
  color: #013057;
}

.makeOfferProject .list-skills .skill {
  background: #e9f6f9;
  color: #0565a2;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-align: center;
  text-transform: capitalize;
  font-size: 90%;
  font-weight: bold;
  padding: 0.3em 0.5em;
}

/* PIANO SELEZIONATO */
.makeOfferProject .selected-plan {
  padding: 1rem 2rem;
  text-align: left;
}

.makeOfferProject .selected-plan > span:nth-child(1) {
  text-transform: capitalize;
  display: block;
}

.makeOfferProject .selected-plan > span:nth-child(2) {
  display: block;
  font-weight: bold;
  font-size: 120%;
  color: var(--bs-primary);
}

/* DESCRIZIONE PROGETTO */
.makeOfferProject .description {
  padding: 1rem;
  margin-top: 1rem;
  color: var(--bs-secondary);
}

.makeOfferProject .description > span:nth-child(1) {
  display: block;
  font-size: 100%;
}

.makeOfferProject .description > span:nth-child(2) {
  display: block;
  font-size: 80%;
  line-height: 22px;
}

/* TEMPLATE BOX */
.makeOfferProject .template {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.makeOfferProject .template .info {
  width: 100%;
  font-size: 90%;
  color: var(--bs-secondary);
  margin-bottom: 0.8rem;
}

.makeOfferProject .template .info span {
  color: var(--bs-primary);
}

/* OTHER LINK - DOC */
.makeOfferProject .info-link,
.makeOfferProject .info-doc,
.makeOfferProject .cliente,
.makeOfferProject .location {
  padding: 1rem;
}

.makeOfferProject .info-link {
  color: var(--bs-secondary);
}

.makeOfferProject .info-link span {
  display: block;
  color: var(--bs-primary);
  font-weight: bold;
  font-size: 110%;
}

.makeOfferProject .info-doc > *, .makeOfferProject .info-link > * {
  color: var(--bs-secondary);
  display: block;
  font-size: 80%;
}

.makeOfferProject .location > * {
  color: var(--bs-secondary);
  display: block;
  font-size: 80%;
}

.makeOfferProject .location > li {
  color: var(--bs-primary);
  font-size: 100%;
}

.makeOfferProject .location > li:nth-child(3) {
  color: var(--bs-primary);
  font-size: 70%;
  font-weight: bold;
  margin-top: -0.4rem;
}

.makeOfferProject .info-doc > li {
  color: var(--bs-primary);
  padding: 0.3rem 0;
  font-size: 100%;
  cursor: pointer;
}

/* AVATAR CLIENT */
.avatar-client {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 80%;
  font-weight: bold;
  gap: 10px;
}

.avatar-client > img {
  border-radius: 50%;
  border: 2px solid var(--bs-primary);
  width: 50px;
  height: 50px;
}

#budget-offer {
  padding: 0.1em 0.1em 0.1em 2em;
  font-size: 150%;
  color: var(--bs-secondary);
}
label[data-default='€'] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

label[data-default='€']:after {
  margin: none;
  position: absolute;
  content: attr(data-default);
  font-size: 150%;
  padding-left: 1em;
  font-weight: bold;
  color: var(--bs-secondary);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.smu-file-upload {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  text-align: center;
  background: cyan;
  cursor: pointer;
  font-size: 90%;
  border-radius: 50%;
  border: 2px solid rgba(128, 128, 128, 0.715);
  background: rgba(211, 211, 211, 0.648);
  transition: 0.5s;
  width: 50px;
  height: 50px;
}
.smu-file-upload > * {
  margin: auto;
}


.smu-file-upload span {
  color:white;
  font-weight: bold;
  font-size: 150%;
}

.smu-file-upload:hover {
  border: 2px solid var(--bs-primary);
  background: rgba(255, 255, 255, 0.648);
}

.add-products .prod {
  padding: 1em;
}
.add-products .prod > * {
  border-radius: 15px;
  border: 2px solid var(--bs-success);
  color: var(--bs-success);
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0.8em;
}
.add-products .prod desc:first-child {
  color: GREEN;
  font-size: 90%;
}