button.nav-link {
    background: transparent;
    border: none;
    opacity: .8;
    transition: .5s opacity;
}

button.nav-link:hover {
    opacity: 1;
    color: white;
}

@media (min-width: 992px) {
    .navbar li.nav-item a {
        font-size: clamp(12px, 1.1vw, 18px) !important;
    }
}