.my-projects {
    padding: 1.2rem;
    background: var(--bs-primary);
    color: white;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 2em;
}

.my-projects.completed {
    background: #D2E9F7;
    color: var(--bs-secondary);
}

.my-projects.products {
    background: rgb(224, 223, 223);
    color: var(--bs-secondary);
}



/* TABLE - BORDER TD */

.my-projects>table td {
    border-bottom: 5px solid var(--bs-primary);
    border-top: 5px solid var(--bs-primary);
}

.my-projects.completed>table td {
    border-bottom: 5px solid #D2E9F7;
    border-top: 5px solid #D2E9F7;
}

.my-projects.products>table td {
    border-bottom: 5px solid rgb(224, 223, 223);
    border-top: 5px solid rgb(224, 223, 223);
}



/* TABLE - BACKGROUND ROW */

.my-projects>table tr:nth-child(odd) {
    background: rgb(224, 242, 255);
}

.my-projects>table tr:nth-child(even) {
    background: white;
}

.my-projects.products>table tr {
    background: rgb(255, 255, 255);
}


/* TABLE - FONT */

.my-projects.products>table .product,
.my-projects.products>table .cost-product {
    font-size: 85%;
    font-weight: bold;
    text-transform: uppercase;
}

.my-projects.products>table .description {
    font-size: 65%;
    font-weight: normal;
    text-transform: none;
}



.my-projects>table {
    width: 100%;
    overflow: hidden;
}

.my-projects>table tr:nth-child(1) {
    background: transparent !important;
    border: none;
    font-size: small;
}

.my-projects>table th {
    padding-bottom: .5em;
}

.my-projects>table td {
    padding: .5em 0;
}

.my-projects>table .name-project {
    color: var(--bs-tertiary);
    text-transform: uppercase;
    font-weight: bold;
}

.my-projects>table .date-project,
.my-projects>table .cost-project {
    color: var(--bs-tertiary);
    font-weight: lighter;
}

.my-projects>table .freelancer>div,
.my-projects>table .status-project>div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--bs-tertiary);
    gap: 10px;
}

.my-projects .avatar-xs {
    border: 2px solid var(--bs-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px !important;
    height: 40px !important;
    overflow: hidden;
}

.my-projects .avatar-xs>img {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.my-projects.products>table .cost-product {
    text-align: center;
}


/* TABLE - WIDTH COLUMNS */

.my-projects>table .name-project {
    width: 25%;
    font-size: 75%;
    padding: .5em;
}

.my-projects>table .date-project,
.my-projects>table .cost-project {
    width: 12.5%;
    font-size: 70%;
    text-align: center;
}

.my-projects>table .freelancer {
    width: 30%;
    font-size: 75%;
}

.my-projects>table .status-project {
    width: 20%;
    font-size: 70%;
}

.my-projects.completed>table .status-project * {
    display: none;
}


.my-projects.products>table .product {
    width:30%;
}
.my-projects.products>table .description {
    width: 50%;
}
.my-projects.products>table .cost-product {
    width:20%;
}

/* RANDOM ELEMENTS */

.yellow-point {
    width: 10px;
    height: 10px;
    background: rgb(230, 218, 5);
    border-radius: 50%;
}

.scheda-project button {
    border: none;
    background: none;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.name-project > button {
    border: none;
    background: none;
    color: var(--bs-primary);
    font-weight: bold;
    padding: 0 .5em;
}

.status-project > button {
    border: 2px solid lightblue;
    background: var(--bs-primary);
    color:white;
    font-weight: bold;
    padding: .7em;
    margin:none;
    align-items: center;
    border-radius: 5px;
}