.mask-loading{
  width:100%;
  height:100%;
  background:#00000075;
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index:999;
  top:0;
  left:0;
}

.rotate {
  animation: rotation 3s infinite ease-in-out;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.text-loading{
    text-align:center;
    letter-spacing:2px;
    color:#fff;
    margin-top:200px;
    font-weight:bold;
    font-size: 2rem;
}