@keyframes showPanel {
    0% {
        top: 100vh;
    }

    100% {
        top: 0;
    }
}

@keyframes showElement {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.template-view {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

}

/* .template-view.show {
    visibility: visible;
    animation-name: showPanel;
    animation-fill-mode: forwards;
    animation-duration: .1s;
} */

.title-template {
    margin: 0em auto 0em auto;
    position: relative;
    padding-top: 1em;
    padding-bottom: 1em;
    background: white;
}


.swiper{
    position: absolute;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);
    background: azure;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.swiper .left-icon {
    position: absolute;
    top: calc(50%);
    left: 1rem;
    transform: translate(-50%, -50%);
    font-size: 90px !important;
    color: var(--bs-primary) !important;
}
.swiper .right-icon {
    position: absolute;
    top: calc(50%);
    right: 1rem;
    transform: translate(-50%, -50%);
    font-size: 90px !important;
    color: var(--bs-primary) !important;
}


