.btn-filterbar-open {
    position: fixed;
    border-radius: 50%;
    left: 10px;
    bottom: 10px;
    background: #0565A2;
    border: none;
    padding: .5em;
    transition: all .5s;
}
.btn-filterbar-open>* {
    color: white;
}
.btn-filterbar-open:hover {
    background: #5093bd;
}
.btn-filterbar-open:hover > * {
    color: #E9F6F9;
}


.btn-filterbar-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    background: #0565A2;
    border: none;
    padding: .5em;
    transition: all .5s;
}
.btn-filterbar-close>* {
    color: white;
}
.btn-filterbar-close:hover {
    background: #5093bd;
}
.btn-filterbar-close:hover > * {
    color: #E9F6F9;
}