.userDetailsList {
    padding: 1em;
    margin-bottom: 1em;
}

.userDetailsList .cliente {
}

.userDetailsList .detail {
    margin-top: 0.5em;
    line-height: 1.2em;
}

.userDetailsList .detail>* {
    color: gray;
    display: block;
    font-size: 80%;
}

.userDetailsList .detail>li {
    color: var(--bs-primary);
    font-weight: bold;
    font-size: 90%;
}

.userDetailsList .detail>li:nth-child(3) {
    color: var(--bs-primary);
    font-size: 70%;
    font-weight: normal;
    margin-top: -0.4rem;
}