.themeA,
.themeB {
    position: absolute;
    z-index: 0 !important;
    opacity: .7;
}

.themeA {
    height: 210%;
    left: -2.5em;
    top:-3.5em
}

.themeB {
    height: 130%;
    right: -2em;
    top:-2em
}

.stepper-project {
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background: transparent !important;
}
.stepper-project .MuiMobileStepper-dots {
    background: transparent;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    gap:5px;
}
.stepper-project .MuiMobileStepper-dot {
    width: 15px;
    height: 15px;
    background: white;
}
.stepper-project .MuiMobileStepper-dotActive {
    background: var(--bs-primary);
}

.tema-scelto {
    position: relative;
    padding:1em;
    height: 3.0em;
}

.tema-scelto > img:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 5px));
    height: 120%;
    z-index: 10;
}
.tema-scelto > .square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - .4px), calc(-50% + 0px));
    border: 1px solid white;
    border-radius: 3px;
    height: 2.2em;
    width: 3.65em;
    z-index: 11;
    overflow: hidden;
}

.tema-scelto > .square img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}

.header-title {
    position: relative;
    z-index: 1;
}


.tema-scelto-lg {
    position: relative;
    height: 5em;
}

.tema-scelto-lg > img:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 14px));
    height: 120%;
    z-index: 10;
}
.tema-scelto-lg > .square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - .6px), calc(-50% + 4px));
    border: 2px solid white;
    border-radius: 3px;
    height: 4.3em;
    width: 6.85em;
    z-index: 11;
    overflow: hidden;
}

.tema-scelto-lg > .square img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}