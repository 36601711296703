.summary-project {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15% 0 0 0;
}

.summary-project > div:first-child,
.summary-project .first-panel,
.summary-project .second-panel,
.summary-project .list,
.resoconto {
  width: 70%;
  max-width: 800px;
}

.summary-project > div:first-child {
  color: var(--bs-secondary);
  text-transform: uppercase;
  font-size: 80%;
  margin-bottom: 1em;
  text-align: left;
}

/* FIRST PANEL */

.summary-project .first-panel {
  background: var(--bs-primary);
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 15px 5% 20px 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.summary-project .first-panel .info {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.summary-project .first-panel .info > *:first-child {
  margin-bottom: 1em;
  padding-left: 5px;
}

.summary-project .first-panel .info > *:last-child {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 13px;
}

.summary-project .first-panel .info > *:last-child h6 {
  padding: 0;
  margin: 0;
  font-size: 70%;
  color: white;
}

.summary-project .first-panel .info > *:last-child desc {
  color: white;
  font-size: 85%;
}

.summary-project .first-panel .template {
  width: 30%;
  position: relative;
}

.summary-project .first-panel .template > img {
  position: absolute;
  width: 190%;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -77%);
}

/* SECOND PANEL */
.summary-project .second-panel {
  background: rgba(211, 211, 211, 0.608);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.summary-project .second-panel {
  display: flex;
  flex-direction: row;
}

.summary-project .second-panel > li {
  list-style: none;
  width: 20%;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.summary-project .second-panel > li * {
  width: 100%;
  text-align: center;
  font-size: 70%;
  text-transform: uppercase;
  color: var(--bs-secondary);
}

.summary-project .second-panel > li h6 {
  line-height: 17px;
  font-size: 110%;
}

.summary-project .second-panel > li:nth-child(4) h6,
.summary-project .second-panel > li:nth-child(6) h6 {
  font-size: 75%;
}

/* FOOTER  */
.resoconto {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
  padding-top: 0em;
  margin: auto;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.footer .btn {
  font-size: 70%;
  margin: 0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 60%;
  padding: 0;
}

.checkbox-label b {
  color: var(--bs-primary);
  margin: 0 3px;
  text-decoration: underline;
  cursor: pointer;
}

/* options list */

.summary-project .list {
  padding: 2em 0.5em .5em 0.5em;
}
.summary-project .list li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: medium;
  border-bottom: 0.5px dotted lightgray;
  padding: 0.5em 0;
}
.summary-project .list li > *:first-child {
  color: var(--bs-secondary);
}
.summary-project .list li > *:nth-child(2) {
  padding: 0;
  margin: 0;
  text-align: end;
}

@media only screen and (max-width: 767px) {
  .summary-project {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15% 0 0 0;
  }

  .summary-project > div:first-child,
  .summary-project .first-panel,
  .summary-project .second-panel,
  .summary-project .list,
  .resoconto {
    width: 100%;
  }

  .summary-project > div:first-child {
    color: var(--bs-secondary);
    text-transform: uppercase;
    padding: 0 5%;
    font-size: 90%;
    margin-bottom: 0em;
    text-align: left;
  }

  /* FIRST PANEL */

  .summary-project .first-panel {
    background: none;
    color: var(--bs-secondary);
    border-top-left-radius: none;
    border-top-right-radius: none;
    padding: 10px 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .summary-project .first-panel .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .summary-project .first-panel .info > *:first-child {
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 120%;
    padding-left: 0px;
  }

  .summary-project .first-panel .info > *:last-child {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 13px;
  }

  .summary-project .first-panel .info > *:last-child h6 {
    padding: 0;
    margin: 0;
    font-size: 70%;
    color: var(--bs-secondary);
  }

  .summary-project .first-panel .info > *:last-child desc {
    color: var(--bs-secondary);
    font-size: 85%;
  }

  .summary-project .first-panel .template {
    width: 100%;
    position: relative;
  }

  .summary-project .first-panel .template > img {
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    transform: translate(0%, 0%);
  }

  /* SECOND PANEL */
  .summary-project .second-panel {
    background: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    flex-direction: column;
  }

  .summary-project .second-panel > li {
    list-style: none;
    width: 100%;
    padding: 15px 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .summary-project .second-panel > li:nth-child(odd) {
    background: #d2e9f7;
  }

  .summary-project .second-panel > li * {
    width: 60%;
    text-align: right;
    font-size: 80%;
    text-transform: uppercase;
    color: var(--bs-secondary);
  }

  .summary-project .second-panel > li *:first-child {
    width: 40%;
    text-align: left;
  }

  .summary-project .second-panel > li h6 {
    line-height: 17px;
    font-size: 110%;
    margin: 0;
    padding: 0;
  }

  .summary-project .second-panel > li:nth-child(1) h6,
  .summary-project .second-panel > li:nth-child(4) h6,
  .summary-project .second-panel > li:nth-child(6) h6 {
    font-size: 80%;
  }

  /* FOOTER  */
  .resoconto {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0em auto 0 auto;
  }

  .footer {
    margin-top: 0rem;
    padding: 0 5%;
    gap: 0.8em;
  }

  .footer .btn {
    font-size: 70%;
    margin: 0;
  }

  .checkbox-label {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: auto 1em;
    margin-top: 1em;
    font-size: 60%;
  }

  .checkbox-label > span {
    display: block;
  }

  .checkbox-label b {
    color: var(--bs-primary);
    display: inline;
    margin: 0 3px;
    text-decoration: underline;
  }

  /* list options */
  .summary-project .list {
    padding: 1em;
    padding-bottom: 0;
  }
  .summary-project .list li > *:first-child {
    font-size: 80%;
  }
  .summary-project .list li > *:nth-child(2) {
    font-size: 75%;
  }
}
