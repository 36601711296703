.dettagli-footer {
  width: 100%;
  background: var(--bs-tertiary);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.5em 0;
}

.dettagli-footer .header {
  font-weight: bold;
  color: white !important;
  text-align: center;
}

.dettagli-footer .description {
    color: lightgray !important;
    font-size: 70%;
    display: block;
    padding: 0 !important;
    margin: 0 !important;
}

.dettagli-footer .allega-mockup {
    text-align: center;
    margin: auto;   
}

.dettagli-footer .allega-mockup > *:nth-child(1) {

    margin-bottom: 1em;
}

.dettagli-footer .allega-mockup > *:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
