.img-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -61.6%);
    z-index: 20;
    width: calc(70% - 20px);
    max-width: 638px;
    height: 394px;
}

.swiper-preview {
    border: 2px solid white;
}
.swiper-preview * {
    background: white;
}

.preview>img {
    width: 100%;
    height: auto;
}



/* ARROW SWIPER */
.arrow-swiper {
    position: absolute;
    width: 90%;
    max-width: 1200px;
}

.arrow-swiper>* {
    cursor: pointer;
}

.arrow-swiper .right-icon {
    float: right;
}

.arrow-swiper .left-icon {
    float: left;
}

@media only screen and (max-width: 991px) {

    .img-preview {
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0%, 0%);
        z-index: 20;
        width: calc(70% - 20px);
        max-width: 638px;
        height: 80vw;
        min-height: 100px;
        max-height: 400px;
        margin-bottom: 2em;
    }
    
    .swiper-preview {
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .preview>img {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
    }



    /* ARROW SWIPER */
    .arrow-swiper {
        position: absolute;
        width: 100%;
        max-width: 1200px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%);
    }

    .arrow-swiper>* {
        cursor: pointer;
    }

    .arrow-swiper .right-icon {
        float: right;
    }

    .arrow-swiper .left-icon {
        float: left;
    }


}