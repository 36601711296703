.stepper-container {
    display: inline-flex;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.smu-vstepper {
    background-color: white;
    margin: 0;
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition:
    transform .6s ease-in-out,
    opacity .6s ease-in-out;
    opacity: 1;
}

.stepper-container .vstep-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.smu-vstepper.active {
    height: auto;
    opacity: 1 !important;
    transition-delay: height 1s;
}

.stepper-container.elem0>.smu-vstepper {
    transform: translateX(0%);
}

.stepper-container.elem1>.smu-vstepper {
    transform: translateX(-100%);
}

.stepper-container.elem2>.smu-vstepper {
    transform: translateX(-200%);
}

.stepper-container.elem3>.smu-vstepper {
    transform: translateX(-300%);
}

.stepper-container.elem4>.smu-vstepper {
    transform: translateX(-400%);
}

.stepper-container.elem5>.smu-vstepper {
    transform: translateX(-500%);
}
.stepper-container.elem6>.smu-vstepper {
    transform: translateX(-600%);
}
.stepper-container.elem7>.smu-vstepper {
    transform: translateX(-700%);
}
.stepper-container.elem8>.smu-vstepper {
    transform: translateX(-800%);
}
