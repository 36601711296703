.alert-page {
    padding:1em;
}

.alert-page > section > desc {
    color: var(--bs-primary);
    font-weight: bold;
}

.alert-page .message {
    color: var(--bs-gray-500);
    font-weight: normal;
}
.alert-page .mittente {
    color:var(--bs-tertiary) !important;
    font-weight: bold;
}