.template-info {
    position: relative;
    padding: 0;
    opacity: 0;
    animation-name: showElement;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: .5s;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.templatePageSelection { border:1px solid #FFF; }
.templatePageSelection.active { border:1px solid #0686D8 !important; }

.template-info>img {
    position: relative;
    width: 900px;
    z-index: 10;
    opacity: 1;
}

.template-info .btnGroup {
    position: absolute;
    transform: translate(-80%, -110%);
    margin-left: -35rem;
    z-index: 11;
}

.template-info .btnGroup>button {
    cursor: pointer;
    text-align: left;
    width: 100%;
    padding-right: 2rem;
    line-height: 20px;
    font-size: 80%;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-bottom-left-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-bottomleft: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

@keyframes showElement {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}