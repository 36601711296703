.progetto-container {
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    overflow-y: hidden;
    min-height: 90vh;
    width: 100%;
}
