.cube {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(70deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(50deg);
}

.cube-wrap {
    perspective: 1000px;
    perspective-origin: 50% 50%;
}

.fl-skew {
    transform: perspective(700px) skew(180deg, 5deg);
    transform-origin: center center;
}
.no-fl-skew {
    transform: perspective(700px) skew(-180deg, -5deg) !important;
    transform-origin: center center;
}

.fl-img {
    position: absolute;
    opacity: 1;
    left: -5rem;
    height: 15rem;
}

.bg-fl-desc {
    background-image: linear-gradient(to left, #d2e9f7d7 85%, #d2e9f700);
}

.bg-fl-desc-disabled {
    background-image: linear-gradient(to left, #dee2e6 85%, #dee2e600)
}

.gray-layer {
    width:100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.367);
}

.grayscale-100 {
    filter: grayscale(100%) !important;
}

.fs-7 {
    font-size: .90rem !important;
  }
  