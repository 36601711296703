.project-panel .info-link,
.project-panel .info-doc,
.project-panel .cliente,
.project-panel .location {
    padding: 1rem;
}

.project-panel .info-link {
    color: var(--bs-secondary);
    font-size: 80%;
}

.project-panel .info-link span {
    display: block;
    color: var(--bs-primary);
    font-weight: bold;
    font-size: 110%;
}

.project-panel .info-doc>* {
    color: var(--bs-secondary);
    display: block;
    font-size: 80%;
}


.project-panel .location>* {
    color: var(--bs-secondary);
    display: block;
    font-size: 80%;
}

.project-panel .location>li {
    color: var(--bs-primary);
    font-size: 100%;
}

.project-panel .location>li:nth-child(3) {
    color: var(--bs-primary);
    font-size: 70%;
    font-weight: bold;
    margin-top: -.4rem;
}


.project-panel .info-doc>li {
    color: var(--bs-primary);
    padding: .3rem 0;
    font-size: 100%;
    cursor: pointer;
}


.project-panel .template {
    height: 80%;
    min-height: 200px;
    max-height: 250px;
}

.project-panel .template > img{
    width: 130%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}