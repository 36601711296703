.add-products {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(1, 48, 87, 1) 50%);
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(1, 48, 87, 1) 50%);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(1, 48, 87, 1) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#013057", GradientType=1);
}

.add-products>h5 {
    color: white;
    text-transform: uppercase;
    width: 100%;
    font-size: 90%;
    text-align: center;
}

.add-products>div {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.add-products .btn-container {
    background: rgb(178, 189, 232);
    display: flex;
    border-radius: 10px;
}

.add-products button {
    opacity: .8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    text-transform: none;
    text-align: start;
    background: white;
    color: var(--bs-secondary);
    border: 3px solid gray;
    border-radius: 10px;
    opacity: .6;
    transition: opacity .4s;
}

.add-products button:hover {
    opacity: 1;
    border: 3px solid var(--bs-primary);
}


.add-products button>desc {
    font-size: 130%;
    width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: .5em;
}

.add-products button>p {
    font-size: 100%;
    font-weight: 600;
    padding: 0;
}


.add-products button.active {
    border: 3px solid rgb(111, 200, 111) !important;
    opacity: 1 !important;
}


.add-products .price {
    font-weight: bolder;
    font-size: 150%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .1s all;
}

.add-products button.active .price {
    color: rgb(70, 156, 70) !important;
    font-size: 180%;
}

.add-products .price>small {
    font-size: 80%;
}