.project-panel {
    border: 2px solid #0688d89a;
    border-radius: 15px;
    overflow: hidden;
    margin:1em 0;
    min-width: 650px;
}

/* HEADER */
.project-panel .name {
    background-color: #033057;
    padding: .3em 1em;
    font-weight: bold;
    color: #FFF;
    font-size: 120%;
    line-height:1.3em;
}

.project-panel .name>span {
    font-size: 60%;
    float: right;
}

/* BODY */
.project-panel .body {
    padding: 1rem 1rem 0rem 1rem ;
    display: flex;
    flex-wrap: wrap;
}

/* BUDGET */
.project-panel .budget {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    width: 95%;
    max-width: 200px;
    padding-top: 1.5em;
    font-size: 70%;
    margin:auto;
    font-weight: lighter;
    box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.1);
}

.project-panel .budget>span:nth-child(1) {
    color: #0565A2;
    font-weight: bold;
    font-size: 190%;
    padding-bottom: .5em;
    margin-top: -.4em;
}

.project-panel .budget>span:nth-child(2) {
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 120%;
    text-align: center;
    background: #0565A2;
    padding: .3em 0;
}

.budget .gold-background {
    background:#FFB703 !important;

}

.project-panel-footer { background:#E9F6F9; width:100%; }

/* DESCRIZIONE */
.project-panel .desc {
    font-size: 70%;
    width: 95%;
    margin: 0 auto;
    color: #013057;
}

.project-panel .desc>span {
    font-size: 140%;
    display: block;
}

/* CLIENT CHOICE */
.project-panel .client {
    width: 95%;
    margin: .5em auto 0 auto;
    color: var(--bs-primary);
    font-size: 90%;
}

/* LISTA SKILLS */
.project-panel .list-skills {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.project-panel .list-skills > span {
    display: block;
    width: 100%;
    font-size: 70%;
    color: #013057;
}

.project-panel .list-skills .skill {
    background: #E9F6F9;
    color: #0565A2;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    text-align: center;
    text-transform: capitalize;
    font-size: 90%;
    font-weight: bold;
    padding: .3em .5em;
}

/* other custom classes */
.btn-project {
    background-color: ;
}