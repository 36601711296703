.portfolio-webdesigner .lg-react-element {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

.portfolio-webdesigner .lg-react-element a {
    overflow: hidden;
    height: 40vw;
    max-height: 160px;
    min-height: 100px;
    padding: .5em;
}

.portfolio-webdesigner a>img {
    width: 100%;
    height: auto;
}