.livePreview {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    background: white;
}

.livePreview header {
    height: 70px;
    width: 100%;
    background-color: var(--bs-secondary);
    display: flex;
    align-items: center;
}

.livePreview footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
}
.livePreview footer iframe {
    height: 100%;
}