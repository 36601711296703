.group-comefunziona .btn, shadow{
    box-shadow: 0rem 0rem .5rem var(--bs-secondary);
}

.group-comefunziona label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: pointer;
}
    
    .group-comefunziona input {
        display: none;
    }
    
    
    
    .pricing-row {
        padding: 0 10px;
        font-size: .9rem;
        text-transform: uppercase;
        margin-bottom: .2rem;
        min-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    
    .pricing-row:nth-child(odd) {
        background-color: rgba(237, 241, 244, .8);
    }
    
    .pricing-row:nth-child(even) {
        background-color: #EDF1F4;
    }
    
    .pricing-row.pricing-title {
        font-size: 17px;
        font-weight: bold;
        height: 60px;
        background: #013057;
        color: #FFF;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    
    .pricing-row.pricing-title.standard {
        background: #0686D8;
    }
    
    .pricing-row.pricing-title.premium {
        background: #00AD8C;
    }
    
    .pricing-row.pricing-label {
        background: none;
        justify-content: right;
        color: white;
        font-weight: bold;
    }
    
    .pricing-container {
        border: 2px solid transparent;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        background: transparent;
    }
    
    .pricing-container.selected {
        border-color: #00AD8C;
    }
    
    /* CFCLIENT */
    
    .geometricD_rlogo_comefunziona,
    .geometricC_llogo_comefunziona {
        opacity: 0.8;
        position: absolute;
        height: 100%;
        font-size: 1rem;
    }
    
    .geometricD_rlogo_comefunziona {
        right: -6rem;
    }
    
    .geometricC_llogo_comefunziona {
        left: -6rem;
    }
    
    .bg-matisse {
        background-color: #0D65A2 !important;
    }
    
    
    @media only screen and (max-width: 992px) {
    
        .geometricD_rlogo_comefunziona,
        .geometricC_llogo_comefunziona {
            opacity: 0.1;
        }

}