.drag-drop-files {
    padding: 0 2rem;
}

.drag-drop-files > * {
    border: 2px dashed #0565a2;
    color: #0565a2;
    text-align: center;
    font-weight: bold;
    padding:2rem;
    border-radius: 15px;
    font-size: 1.5rem;
    transition: all .6;
}

.drag-drop-files.active > * {
    color:gray;
    border-color: gray;
    background-color: rgba(255, 255, 255, 0.615);
}

