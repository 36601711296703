.freel-detail {
    background-color: white;
}

.freel-detail-img {
    overflow: hidden;
    height: 200px;
    padding: .5em;
}

.freel-detail-img>* {
    width: 100%;
}


.title {
    color: var(--bs-secondary);
    width: 100%;
}


.freel-detail .bar {
    width: 85%;
    height: 2px;
    border-radius: 50%;
    background: #0565A2;
    margin: 1em auto;
}


.badge-sm {
    position: absolute;
    width: 85px;
    height: 85px;
    z-index: 200;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 3em), calc(-50% + 4.5em));
}

/* BADGE FREELANCER */
.freel-detail .badge {
    transform: scale(.9);
    width: 100%;
    margin: -1em auto -2em auto;
}


/* BIO FREELANCER */
.freel-detail .bio {
    width: 85%;
    margin: 0 auto 1em auto;
    text-align: center;
    color: var(--bs-primary);
}

.freel-detail .bio>*:nth-child(2) {
    font-size: 70%;
    text-align: left;
    color: var(--bs-secondary);
}