.smu-ballet-point {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding-top: .1rem;
    margin: none;
    font-size: 70%;
    border-radius: 50%;
    background: #ffffff70;
    color: var(--bs-secondary);
    transition: 0.5s;
    border: 0px solid white;
    cursor: pointer;
}

.smu-ballet-point.active {
    background: var(--bs-primary);
    border: 1px solid white;
    font-weight: bold;
    transform: scale(1.15);
    color: white;
    box-shadow: 0 0 3px #fff;
}

.smu-ballet-text {
    color:white;
}