.search-bar {
    width:100%;
    border-radius: 15px;
    border:2px solid #0688d89a;
    background: rgba(211, 211, 211, 0.219);
    font-size: 110%;
    height:50px;
    padding: 0 calc(1em + 5%);
    transition: all .5s;
}

.search-bar:focus {
    border:2px solid rgb(7, 175, 7);
    background: rgba(211, 211, 211, 0.089);
    box-shadow: none;
    outline: none;
    outline-offset: none;
}