.profileHeading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileHeading {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #013057;
}

.card.bg-lightblue,
.card.bg-lightblue .card-header,
.bg-lightblue {
    background: #0686D8;
    color: #FFF;
}

.card.bg-cyan,
.card.bg-cyan .card-header,
.bg-cyan {
    background: #D2E9F7;
}

.bg-lightcyan {
    background: #eff5f8 !important;
}

.form-card .input-group.input-group-static .form-control {
    background: #FFF !important;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.form-card label {
    color: #FFF;
}

.form-card.bg-cyan label {
    color: #013A63;
}

.mh-358 {
    min-height: 358px;
}