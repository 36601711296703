.btn-drawer {
    background-color: lightgray;
    border:none;
    border-radius: 15%;
    width:55px;
    color:dark;
    position: absolute;
    top:10px;
    text-align: left;
    padding: 0 0.3rem 0.3rem 0.3rem;
    right: -15px;
    font-size: 2rem;
    z-index:2;
}


.close-drawer {
    position:absolute;
    left:15px;
    top:18px;
    border:none;
    border-radius: 50%;
    height: 50px;
    width: 40px;
    color:white;
    background: rgb(6, 134, 216);
    transition: .6s all;
    font-size: 1.5rem;
    transform: scaleX(1.25);
    opacity: .6;
    cursor: pointer;
    z-index: 1201;
}

.close-drawer:hover {
    transform: rotateZ(180deg) scaleX(1.25);
    opacity: 1;
}
