.payment {
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: relative;
    background-color: white;
    width: 100%;
    min-height: 100px;
    border-radius: 0.75rem;
    overflow: hidden;
    border: none;
}

.payment div {
    background-color: rgba(97, 95, 95, 0.45);
    height: 110%;
    width: 110%;
    top: -5%;
    left: -5%;
    margin: 0;
    position: absolute;
    z-index: 5;
    transition: background 0.5s ease-in-out;
}

.payment div:hover {
    background-color: rgba(97, 95, 95, 0.1);
}

.payment.active {
    border: 3px solid #4CAF50 !important;
}

.payment.active div {
    background-color: rgba(97, 95, 95, 0);
}


.img-payment {
    z-index: 4;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 70%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
}

@media only screen and (min-width: 992px) {
    .centered-element-lg {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}