.client-offer {
    margin: 3em auto;
    padding: auto;
    display: flex;
    flex-wrap: wrap;
}


.client-offer .form-control {
    background: #EDF1F4;
    border:2px solid #EDF1F4;
    padding: .8em 1em;
    transition: box-shadow .5s;
    color: black;
    font-weight: bold;
}

.client-offer .form-control.active {
    border:2px solid lightgreen;
    background: #EDF1F4;
}

.client-offer .form-control:focus {
    border: 2px solid var(--bs-primary);
    background: white;
    box-shadow: 0px 0px 15px -5px #0686d8;
}

.client-offer .desc {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 1rem;
    color: var(--bs-secondary);
}

.client-offer>div {
    padding: 1em;
}

.plus-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    outline: none;
    background: none;
    color: #0565A2;
    border:2px solid #0565A2;
    font-size: 90%;
    margin-left: 10px;
}

.inputFile {
    display: flex;
    align-items: center;
    margin-bottom: .5em;
}