.template-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background: #EDF1F4;
    overflow: hidden;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
}

.fs-small {
    font-size: 50% !important;
    font-weight: normal;
}

@media (min-width:  768px) {
    .fs-small {
        font-size: 60% !important;
    }    
}