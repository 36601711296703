.caratteristiche {
    color: var(--bs-secondary);
}

.caratteristiche {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    width: 500px;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.95);
    z-index: 10;
    opacity: 0;
    transition: opacity .5s;
    transition-delay: .1s;
    max-height: 370px;
    overflow: auto;

}

.caratteristiche.active {
    z-index: 50;
    opacity: 1;
}

.caratteristiche desc {
    font-weight: bold;
    font-size: 90%;
}

.caratteristiche ul {
    padding: 0;
    margin: 0;
}

.caratteristiche li {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 70%;
    padding: .5rem .5rem;
}

.caratteristiche li:not(:last-child) {
    border-bottom: 1px solid var(--bs-primary);
}

.caratteristiche li>span:first-child {
    text-align: left;
    display: block;
    color: gray;
    width: 40%;
}

.caratteristiche li>span:last-child {
    text-align: right;
    display: block;
    position: relative;
    color: var(--bs-secondary);
    width: 60%;
    font-weight: bold;
}


@media only screen and (max-width: 991px) {
    .caratteristiche {
        position: relative;
        display: block;
        top: auto;
        left: auto;
        transform: translate(0%, 0%);
        width: 100%;
        max-width: 1000px;
        padding: 1rem 5%;
        background: rgba(255, 255, 255, 0.95);
        z-index: 10;
        opacity: 1;
        transition: opacity .5s;
        transition-delay: .1s;
        max-height: none;
    }

    .caratteristiche.active {
        z-index: 50;
        opacity: 1;
    }

    .caratteristiche desc {
        font-weight: bold;
        font-size: 90%;
    }

    .caratteristiche ul {
        padding: 0;
        margin: 0;
    }

    .caratteristiche li {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        font-size: 70%;
        padding: .5rem .5rem;
    }

    .caratteristiche li:not(:last-child) {
        border-bottom: 1px solid var(--bs-primary);
    }

    .caratteristiche li>span:first-child {
        text-align: left;
        display: block;
        color: gray;
        width: 40%;
    }

    .caratteristiche li>span:last-child {
        text-align: right;
        display: block;
        position: relative;
        color: var(--bs-secondary);
        width: 60%;
        font-weight: bold;
    }

}