.selection-button-container { gap:12px; width:100%; margin-top:60px; }
.selection-button { transition:all .5s ease-out; aspect-ratio: 4 / 3; text-align: center; background:#FFF; border:2px solid #7C7B7E; appearance: none; border-radius: 10px; padding:15px 0; }
.selection-button.no-ratio { aspect-ratio:initial; }
.selection-button.selected { background:#FFB703; border:2px solid #FFF; -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.28); 
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.28); }
.selection-button--over-image { width:60%; margin:-100px auto 0; padding-bottom:20px; }
.selected .selection-button--over-image {  animation:jumpAnimation 1s forwards ease-in-out; }
.selection-button--text { font-size:1.8rem; line-height:1em; }

.MuiFilledInput-root { background:#EDF1F4 !important; padding:15px 25px !important; border-radius:10px !important; }
.MuiFilledInput-root:before, .MuiFilledInput-root:after { border-bottom:0 !important; }
.MuiFilledInput-input { padding-top:0 !important; padding-bottom:0 !important; }
.MuiSelect-icon { display:block !important; width:2em !important; height:2em !important; top:calc(50% - 1em) !important; }

.gold { color:#FFB703 !important; }

.template-box { border:0; transition:all 0.5s ease-out; font-size:1.2em; }
.template-box.active { background:#FFB703; }

.header-title p { color:#FFF; }

@keyframes jumpAnimation {
    0% { margin-top:-100px; margin-bottom:0; }
    50% { margin-top:-130px; margin-bottom:30px; }
    100% { margin-top:-100px;  margin-bottom:0;}
    
}