.team-selection {
    display:none;
  }

  .team-selection + label
   {
       display:inline-block;
       position:relative;
       margin-bottom:80px;
       background: #FFF;
       padding: 0 0 0 0px;
       width:100%;
       aspect-ratio: 1/1;
       border:3px solid #FFF;
       border-radius:30px;
       -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.28); 
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.28);
      cursor:pointer;
   }

 
   .team-selection:checked + label
    {
       background: #FFB703;
       
    }

    .team-selection:checked + label img
    {
      animation:jumpAnimationTeam 1s forwards ease-in-out;
       
    }

   


    .team-selection + label img { margin:-20px auto 0; padding:0 15px; }
    .team-selection + label h4 {  text-align: center; font-size:1.2rem; color:#666; }
    .team-selection:checked + label h4 { color:#0565a2; }
   

    @keyframes jumpAnimationTeam {
      0% { margin-top:-20px; margin-bottom:0; }
      50% { margin-top:-50px; margin-bottom:30px; }
      100% { margin-top:-20px;  margin-bottom:0;}
      
  }
