.filterbar {
    display:flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    height:100%;
    max-width: 95%;
    min-width: 230px;
    background:transparent;
    padding: 0 .5em;
    overflow-y: auto;
    margin:0 !important;
}

.filterbar .title {
    color:#013057;
    font-weight: bold;
    font-size: 85%;
}

.filterbar .budget {
    display: flex;
    margin-top:5px;
    align-items: center;
}

.filterbar ul,
.filterbar li {
    margin: 0;
    padding: 0;
}

.filterbar li {
    list-style: none;
    display: flex;
}

.filterbar section {
    padding: 0 .5em;
}


.filterbar label[for] {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    margin:0;
}

.filterbar .label::first-letter {
    margin-left: 1em;
    text-transform: uppercase;
}

.filterbar .label-text {
    font-weight: normal;
    font-size: 97%;
}


/* divisore */
.filterbar .bar {
    width:95%;
    height: 1px;
    border-radius: 50%;
    background: #0688d89a;
    margin: 1em auto;
}

