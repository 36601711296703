h1,
.h1 {
    font-size: 3.2rem;
    letter-spacing: 0.05rem !important;
    text-transform: uppercase;
}

h1,
.h1,
h2,
.h2 {
    color: var(--bs-primary);
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: var(--bs-tertiary);
}

h2,
.h2 {
    font-size: 2.4rem;
    letter-spacing: 0.03rem;
}

h3,
.h3 {
    font-size: 1.8rem;
    letter-spacing: 0.01rem;
}

p {
    font-size: 1.16rem;
    color: var(--bs-secondary);
    font-weight: 400;
}

.z-index-n1 {
    z-index: -1;
}
.header-title p { color:#FFF; }

.z-index-n2 {
    z-index: -2;
}

.subtitle-hero {
    font-size: 2rem;
    font-family: Zilla Slab;
}

.subtitle {
    font-size: 1.6rem;
}

a {
    color: var(--bs-tertiary) !important;
}

a.nav-link {
    color: var(--bs-white) !important;
    opacity: .7;
}

a.nav-link:hover {
    color: var(--bs-white) !important;
    opacity: 1;
    transition: all .5s ease-in;
}

a:hover,
a:focus {
    color: var(--bs-secondary) !important;
}

.btn {
    border: 0 !important;
    color: var(--bs-primary);
    box-shadow: none;
    display: inline-block;
    font-size: 1.1rem;

}

button.btn-lg,
.btn-group-lg>button.btn {
    padding: 0.4rem 1.2rem !important;
    font-size: 1.35rem !important;
    border-radius: 0.7rem;
}

.btn-white {
    background: var(--bs-white);
    color: var(--bs-primary) !important;
}

.btn-gray {
    background: #ffffff90;
    color: var(--bs-primary) !important;
}

.btn-white:hover {
    background-color: #fff;
    border: none;
    color: var(--bs-secondary) !important;
    box-shadow: 0 14px 26px -12px rgb(255 255 255 / 40%), 0 4px 23px 0 rgb(255 255 255 / 15%), 0 8px 10px -5px rgb(255 255 255 / 20%);
    transition: all .5s ease-in;
}

.btn-primary {
    color: var(--bs-white) !important;
    background: var(--bs-primary) !important;
}

.btn-primary:hover {
    color: var(--bs-white) !important;
    background: var(--bs-secondary) !important;
    box-shadow: 0 14px 26px -12px rgb(255 255 255 / 40%), 0 4px 23px 0 rgb(255 255 255 / 15%), 0 8px 10px -5px rgb(255 255 255 / 20%);
    transition: all .5s ease-in;
}

.bl-1 { border-left:1px solid #FFF; }
.bl-b-1 { border-left:1px solid #707070; }

.countdownValue { position:relative; }
.countdownValue::after {
    content:"";
    position: absolute;
    display:block;
    width:1px;
    height:55%;
    right:0;
    top:18%;
    background:#FFF;
}

.btn-yellow { 
    background:rgb(255, 183, 3) !important;
    color:#013A63 !important;
    border:2px solid #EBEAEB !important;
}

.btn-yellow:hover {
    color: var(--bs-white) !important;
    background: #FFB703 !important;
    box-shadow: 0 14px 26px -12px rgb(255 183 3 / 40%), 0 4px 23px 0 rgb(255 183 3 / 15%), 0 8px 10px -5px rgb(255 183 3 / 20%);
    transition: all .5s ease-in;
}


.bg-img-info {
    background-image: url('../images/sitemeup/bg_perche_sitemeup.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.text-lg {
    font-size: 1.8rem !important;
    font-family: Zilla Slab;
    font-weight: bolder !important;
    line-height: 1.45;
}

/* info text */
.text-info-lg,
.text-info,
.text-info-sm {
    font-weight: lighter;
    line-height: 1.5;
    color: #0565A2 !important;
}

.text-info-lg {
    font-size: 2.5rem !important;
}


.text-info-sm {
    font-size: 0.8rem !important;
}

.font-size-4 {
    font-size: 1.8rem !important;
    font-weight: 400;
}

.font-size-3 {
    font-size: 1.8rem !important;
    font-weight: 400;
}

.font-size-2 {
    font-size: 1.4rem !important;
    font-weight: 400;
}

.font-size-1 {
    font-size: 1.2rem !important;
    font-weight: 400;
}

.font-size-0 {
    font-size: 0.8rem !important;
    font-weight: 400;
}

.text-lead {
    font-size: .9em;
}

.immagine-apertura img {
    width: 30px;
    width: 30px;
}

.news-archivio h4 {
    font-size: 1rem;
}

.lato {
    font-family: "Lato", Helvetica, Arial, sans-serif !important;
}

.img-hp-AboutUs {
    height: 250px;
}

.cover img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.box-shadow-none {
    box-shadow: none !important;
}

.zilla {
    font-family: Zilla Slab !important;
}

/* geometric logos */
.geometricB_llogo_landing,
.geometricA_rlogo_landing,
.geometricA_llogo,
.geometricA_rlogo,
.geometricB_llogo,
.geometricB_rlogo,
.geometricC_llogo,
.geometricC_llogo_footer,
.geometricC_rlogo,
.geometricD_llogo,
.geometricD_rlogo {
    opacity: 0.8;
    position: absolute;
    height: 100%;
    font-size: 1rem;
}

.geometricA_llogo {
    left: -2em !important;
}

.geometricA_rlogo_landing {
    right: -22rem !important;
}

.geometricB_llogo_landing {
    left: -11rem !important;
}


.geometricA_rlogo {
    right: -14rem !important;
}

.geometricB_llogo {
    left: -7rem !important;
}

.geometricB_rlogo {
    right: -1em !important;
}

.geometricC_llogo {
    left: -4em !important;
}

.geometricC_llogo_footer {
    left: -0.5em !important;
}

.border-radius-none {
    border-radius: 0 !important;
}

.geometricC_rlogo {
    right: -1em !important;
}

.bg-none {
    background: none !important;
}

.geometricD_llogo {
    left: -1em !important;
}

.btn-modifica-bio {
    position: absolute !important;
    top: -10px;
    right: -10px;
    z-index: 3;
    cursor: pointer;
}

.geometricD_rlogo {
    right: -1em !important;
}

@media (min-width: 992px) {

    .slider-larrow {
        left: -6% !important;
    }

    .slider-rarrow {
        right: -6% !important;
    }

    .min-height-lg-150 {
        min-height: 150px !important;
    }
}

@media only screen and (min-width: 992px) {
    .border-lg-radius-xl {
        border-radius: 0.75rem !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 992px) {
    .row.md-bg-none {
        background: none !important;
    }

    .geometricB_llogo_landing,
    .geometricA_rlogo_landing,
    .geometricA_llogo,
    .geometricA_rlogo,
    .geometricB_llogo,
    .geometricB_rlogo,
    .geometricC_llogo,
    .geometricC_llogo_footer,
    .geometricC_rlogo,
    .geometricD_llogo,
    .geometricD_rlogo {
        opacity: 0.3;
    }
}

@media (max-width: 767px) {
    .text-center-sm {
        text-align: center !important;
    }

    .font-size-sm-5 {
        font-size: 1.8rem !important;
        font-weight: 600;
    }

    .font-size-sm-4 {
        font-size: 1.6rem !important;
        font-weight: 500;
    }

    .font-size-sm-3 {
        font-size: 1.4rem !important;
        font-weight: 400;
    }

    .font-size-sm-2 {
        font-size: 1.2rem !important;
        font-weight: 400;
    }

    .font-size-sm-1 {
        font-size: 1rem !important;
        font-weight: 400;
    }

    .font-size-sm-0 {
        font-size: 0.6rem !important;
        font-weight: 400;
    }

    h1,
    .h1 {
        font-size: 10vw;
    }

    h2,
    .h2 {
        font-size: 8vw;
    }

    h3,
    .h3 {
        font-size: 5vw;
    }

    .subtitle-hero {
        font-size: 6vw;
        font-family: Zilla Slab;
    }

    .subtitle {
        font-size: 5vw;
    }

    .btn {
        font-size: 3.4vw;
        display: block !important;
        margin: auto !important;

    }

    button.btn-lg,
    .btn-group-lg>button.btn {
        padding: 0.2rem .8rem !important;
        font-size: 3.8vw !important;
        border-radius: 0.7rem;
    }
}

.immagine-apertura img {
    width: 30px;
}

.news-archivio h4 {
    font-size: 1rem;
}


.zilla {
    font-family: Zilla Slab !important;
}

.mail-icon {
    border-radius: 20px 0 0 20px;
    margin-top: .5rem;
    padding-left: 0px;
    padding-bottom: 0px;
    right: 0rem;
    width: 50px;
    border: none;
    background-color: white;
    position: absolute;
    font-size: 1.2em;
    cursor: pointer;
    z-index: 0;
}

.mail-icon:nth-child(2) {
    margin-top: 3.5rem;
}

.mail-icon div>span {
    background-color: rgb(219, 2, 2);
    color: white;
    font-size: 0.6rem;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-weight: bold;
    position: absolute;
    top: calc(50% - 14px);
    left: calc(50% - 0px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mail-icon:nth-child(2) div>svg {
    transform: rotate(-30deg);
}

.border-radius-none {
    border-radius: 0 !important;
}

.bg-none {
    background: none !important;
}

.btn-modifica-bio {
    position: absolute !important;
    top: -10px;
    right: -10px;
    z-index: 3;
    cursor: pointer;
}

.uploadImage {
    border: 3px solid;
    width: 100%;
    height: 150px;
    text-align: center;
    font-size: 400%;
    cursor: pointer;
}

.clear-icon {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.95);
    font-weight: bold;
    cursor: pointer;
    z-index: 3;
    padding: 0rem .35rem;
    right: 0.2rem;
    top: -.4rem;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.text-gray {
    color: gray !important;
}

.portrait img {
    height: 120% !important;
    width: auto !important;
}

.landscape img {
    width: 120% !important;
    height: auto !important;
}

@media only screen and (min-width: 992px) {
    .border-lg-radius-xl {
        border-radius: 0.75rem !important;
    }
}

@media only screen and (max-width: 992px) {
    .row.md-bg-none {
        background: none !important;
    }
}

.text-gold {
    color: #b99e06 !important;
}

.btn-save {
    transition: all .7s;
}

.scrollbar-typeA::-webkit-scrollbar {
    width: 5px;
}

.scrollbar-typeA::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: var(--bs-primary);
}

.scrollbar-tertiary::-webkit-scrollbar-thumb {
    background: var(--bs-tertiary) !important;
}

.scrollbar-typeA::-webkit-scrollbar-track {
    background: "white";
    border-radius: 15px;
}



.smu-form-control {
    width: 100%;
    background: #EDF1F4;
    border: 2px solid #EDF1F4;
    padding: .2em 1em;
    border-radius: 10px;
    transition: box-shadow .5s;
    color: black;
    font-weight: bold;
    outline: none;
}

.smu-form-control.active {
    border: 2px solid lightgreen;
    background: #EDF1F4;
}

.smu-form-control:focus {
    border: 2px solid var(--bs-primary);
    background: white;
    box-shadow: 0px 0px 15px -5px #0686d8;
}


.avatar-img-xl {
    width: 90%;
    max-width: 250px;
    max-height: 250px;
    min-width: 150px;
    min-height: 150px;
    border: 3px solid var(--bs-primary);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-img-md {
    width: 90%;
    max-width: 200px;
    max-height: 200px;
    min-width: 100px;
    min-height: 100px;
    border: 3px solid var(--bs-primary);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-img-sm {
    width: 90%;
    max-width: 150px;
    max-height: 150px;
    min-width: 50px;
    min-height: 50px;
    border: 3px solid var(--bs-primary);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.avatar-img-sm>*,
.avatar-img-md>*,
.avatar-img-xl>* {
    width: 100%;
    height: 100%;
}


.bg-aliceblue {
    background: #EDF1F4 !important;
}


.transition-3 {
    transition: .3s all !important;
}

.parent-flex {
    display: flex !important;
    align-items: center;
}

.rotate-90 {
    transform: rotateZ(90deg) !important;
}

.rotate-45 {
    transform: rotateZ(45deg) !important;
}

.w-97 {
    width: 97% !important;
}