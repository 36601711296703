.filter-list {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    width: 100%;
    overflow-x: auto;
}

.filter-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.filter-list::-webkit-scrollbar {
    height: 10px;
    background-color: #F5F5F5;
}

.filter-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
            left bottom,
            left top,
            color-stop(0.44, var(--bs-primary)),
            color-stop(0.72, var(--bs-primary)),
            color-stop(0.86, var(--bs-primary)));
}

.filter-list>li {
    position: relative;
    list-style-type: none;
    text-align: center;
    display: flex;
}

.filter-list>li>button {
    cursor: pointer;
    color: var(--bs-primary);
    background: none;
    border: none;
    border-radius: 10px;
    min-width: 70px;
    width: 100%;
    height: 100%;
    margin: 0rem .5rem;
    padding: .5rem;
    font-size: small;
    text-transform: inherit;
    transition-duration: .4s;
    transition-property: all;
}

.filter-list>li>button:hover {
    color: white;
    background-color: #66aedb;
}

.filter-list>li.active>button {
    background-color: var(--bs-primary);
    color: white;
    text-decoration: none;
}

.template-list {
    overflow-x: hidden;
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.turn-back-btn {
    padding: 0;
    margin:0;
    outline: none;
    border:none;
    background: none;
    font-weight: bold;
    color: var(--bs-primary);
}