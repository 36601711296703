.SMURegistration .MuiPaper-root {
    background: #033057;
    color: #FFF;
    padding: .5em;
    border-radius: 0;
    width: 100%;
}

.SMURegistration h2 {
    text-transform: none;
    letter-spacing: 0.08em !important;
}

.SMURegistration .MuiFormControlLabel-label {
    color: #FFF !important;
}


label.MuiFormLabel-root.MuiInputLabel-shrink {
    transform: translate(10px,  -18px) scale(0.9) !important;
    color:white;
}

.SMURegistration .btn-login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-tertiary);
    gap: 8px;
}

.SMURegistration .btn-login-form>button {
    border: none;
    background: none;
    outline: none;
    text-transform: capitalize;
    color: gray;
    font-size: 70%;
    transition: .2s all;
    font-weight: bold;
}

.SMURegistration .btn-login-form>button.active {
    color: white;
}